import React, { useState } from "react";
import Layout from "../../../../components/Layout";
import Seo from "../../../../components/seo";
import bgImage from "../../../../images/aichapter/chitra-hero-bg-updated.png";
import ChitraMusicianBgImg from "../../../../images/aichapter/chitra-Musicians.png";
import ChitraPodcastBgImg from "../../../../images/aichapter/chitra-Podcasters.png";
import ChitraYoutubeBgImg from "../../../../images/aichapter/chitra-YouTubers.png";
import ChitraBloggerBgImg from "../../../../images/aichapter/chitra-Bloggers.png";
import ChitraArtworkBgImg from "../../../../images/aichapter/chitra-Artwork.png";
import ChitraMusicianSvgImg from "../../../../images/aichapter/chitra-musicians-logo.svg";
import ChitraPodcastSvgImg from "../../../../images/aichapter/chitra-podcast-logo.svg";
import ChitraYoutubeSvgImg from "../../../../images/aichapter/chitra-youtubers-logo.svg";
import ChitraBloggerSvgImg from "../../../../images/aichapter/chitra-bloggers-logo.svg";
import ChitraArtworkSvgImg from "../../../../images/aichapter/chitra-artwork-logo.svg";
// import ogIMage from "../../../../images/aichapter/OgImage_Chitra.png";
import ogIMage from "../../../../images/aichapter/Og-Image-chitra-updated.png";
import FeatureImgChitra from "../../../../images/aichapter/Feature-chitra-img.png";
import AppchitraVideo from "../../../../images/aichapter/videos/app-chitra.mp4";
import FeatureChitraOneSVG from "../../../../images/aichapter/chitra-feature-1.svg";
import FeatureChitraTwoSVG from "../../../../images/aichapter/chitra-feature-2.svg";
import FeatureChitraThreeSVG from "../../../../images/aichapter/chitra-feature-3.svg";
import FeatureChitraFourSVG from "../../../../images/aichapter/chitra-feature-4.svg";
import * as ChitraCreativity from "../../../../animations/chitra-creativity.json";
import * as ChitraEfficiency from "../../../../animations/chitra-efficiency.json";
import * as ChitraPersonalization from "../../../../animations/chitra-personalization.json";
import { Link } from "gatsby";
// import { StaticImage } from "gatsby-plugin-image";
import FeaturesOfDemos from "../../../../components/FeaturesOfDemos";
import Lottie from "react-lottie";
import NavigateBlockV2 from "../../../../components/NavigateBlockV2";
import ExplainedDemoAI from "../../../../components/ExplainedDemoAI";
// import { Helmet } from "react-helmet";
import CountIncrement from "../../../../components/CountIncrement";
import { GenericModal } from "../../../../components/GenericModalCard";
import { CollectEmailAddressForm } from "../../../../components/collect-email-address-form";

const DEMOS = {
    SEA: "SEA",
    CHITRA: "Chitra",
};
const Imchitra = () => {
    const [open, setOpen] = useState(false);
    const [openFor, setOpenFor] = useState();
    const [redirectUrl, setRedirectUrl] = useState();

    const openForm = (demoName) => {
        setOpenFor(demoName);
        if (demoName === DEMOS.CHITRA) {
            setRedirectUrl("https://chitra.tarkalabs.com/songs");
        } else {
            setRedirectUrl("https://sea.tarkalabs.com/");
        }
        setOpen(true);
    };
    const NavbarTheme = {
        color: "light",
        paddingTop: "0px",
    };
    const ListOfChitraFeatures = [
        {
            id: 1,
            imageSrc: FeatureChitraOneSVG,
            title: "AI-Driven Design",
            description:
                "Chitra utilizes AI technology to interpret song lyrics and generate visually stunning artwork that resonates with your music.",
        },
        {
            id: 2,
            imageSrc: FeatureChitraTwoSVG,
            title: "Keyword Extraction",
            description:
                "By extracting keywords from song lyrics, Chitra ensures that the generated artwork reflects the themes and emotions conveyed in the music.",
        },
        {
            id: 3,
            imageSrc: FeatureChitraThreeSVG,
            title: "Unique Designs",
            description:
                "No more generic album covers. With Chitra, get unique artwork tailored to each song, giving your music a distinctive visual identity.",
        },
        {
            id: 4,
            imageSrc: FeatureChitraFourSVG,
            title: "User-Friendly Interface",
            description:
                "With Chitra, creating album artwork is as simple as selecting a song, Out intuitive interface makes it easy for artists and musicians to generate captivating cover designs in seconds.",
        },
    ];
    const ListOfExplainAI = [
        {
            id: 1,
            btnTitle: "Musicians",
            svgImg: ChitraMusicianSvgImg,
            title: "Create stunning album covers effortlessly",
            content:
                "EPerfect for musicians releasing singles, EPs, or full albums, our platform allows you to design eye-catching covers that resonate with your music's unique vibe. Say goodbye to generic artwork and hello to customised, professional-quality visuals that make your music stand out.",
            image: ChitraMusicianBgImg,
        },
        {
            id: 2,
            btnTitle: "Podcasters",
            svgImg: ChitraPodcastSvgImg,
            title: "Engaging cover artwork for shows",
            content:
                "Whether you're launching a new show or rebranding an existing one, our platform offers tailored, high-quality cover art that captures your podcast's essence. Make a lasting impression on your audience with engaging visuals that complement your content.",
            image: ChitraPodcastBgImg,
        },
        {
            id: 3,
            btnTitle: "YouTubers",
            svgImg: ChitraYoutubeSvgImg,
            title: "Boost YouTube presence with Channel Art and Thumbnails",
            content:
                "Generate compelling visuals that attract viewers and increase engagement. From custom channel banners to click-worthy thumbnails, Chitra ensures your YouTube content looks as good as it sounds.",
            image: ChitraYoutubeBgImg,
        },
        {
            id: 4,
            btnTitle: "Bloggers",
            svgImg: ChitraBloggerSvgImg,
            title: "Graphics for Articles or Social Posts",
            content:
                "Bring your blog to life with Chitra's AI-generated graphics. Ideal for articles and social media posts, our platform provides you with striking visuals such as feature images, infographics, that enhance your content and capture readers' attention.",
            image: ChitraBloggerBgImg,
        },
        {
            id: 5,
            btnTitle: "Anyone Looking for Images and Artwork",
            svgImg: ChitraArtworkSvgImg,
            title: "Unique stylized images for any need",
            content:
                "Whether you're an artist, designer, or enthusiast, Chitra's AI-powered platform offers endless possibilities for creating stylized images and artwork. Experiment with different styles, themes, and concepts to produce unique visuals that reflect your personal or brand aesthetic.",
            image: ChitraArtworkBgImg,
        },
    ];
    return (
        <Layout NavbarTheme={NavbarTheme}>
            {/* <Helmet>
                <script
                    async
                    src="https://d1fmfone96g0x2.cloudfront.net/tarka-chat-2.4.0.umd.js"
                ></script>
            </Helmet> */}
            <Seo
                title="Music Cover Artwork AI Solution Chitra - Sedin"
                description="Unleash your music's visual potential with Chitra, the AI-powered solution to customize and create stunning, unique album covers that reflect your music style."
                image={`https://sedintechnologies.com${ogIMage}`}
            />
            {/* <section
                className="AI-common-herostyle leverage-AI"
                style={{
                    background: `url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                }}
            >
                <div className="container container--banner">
                    <div className="AI-common-herostyle__text">
                        <div className="AI-common-herostyle__text-breadcrumbs text-fw-normal gap-2x text-clr-primary text-clr-white">
                            <Link className="text-decor-none" to="">
                                AI Chapter
                            </Link>
                            /
                            <Link className="text-decor-none" to="/ai-chapter/solutions">
                                Solutions
                            </Link>
                            /
                            <Link className="text-decor-none" to="">
                                Chitra
                            </Link>
                        </div>
                        <h1 className="text-h1 AI-common-herostyle__text-header text-fw-medium gap-2x text-clr-white">
                            Chitra
                        </h1>
                        <p className="text-p1 AI-common-herostyle__text-description text-fw-regular gap-2x text-clr-white">
                            Capture the Essence of Your Music <br />
                            with AI-Generated album artwork
                        </p>
                        <div>
                            <Link className="sdn-cta-btn" to="/contact/">
                                Contact us
                            </Link>
                        </div>
                    </div>
                    <div className="ai-demos-stats chitra-stats">
                        <p className="text-p1 AI-common-herostyle__text-description text-fw-regular gap-2x text-clr-white">
                            Musicians focus on music. Chitra perfects their album covers
                        </p>
                        <div className="ai-demos-stats__grid">
                            <div className="ai-demos-stats__grid-item">
                                <h1 className="text-h1 AI-common-herostyle__text-description text-fw-medium gap-05x text-clr-white">
                                    <CountIncrement start={0} end={100} duration={4000} showPlus />
                                </h1>
                                <p className="text-p3 AI-common-herostyle__text-description text-fw-regular text-clr-secondary">
                                    Layouts, fonts, colors and graphics
                                </p>
                            </div>
                            <div className="ai-demos-stats__grid-item">
                                <h1 className="text-h1 AI-common-herostyle__text-description text-fw-medium gap-05x text-clr-white">
                                    <CountIncrement start={0} end={100} duration={4000} />
                                </h1>
                                <p className="text-p3 AI-common-herostyle__text-description text-fw-regular text-clr-secondary">
                                    Download in high resolution
                                </p>
                            </div>
                            <div className="ai-demos-stats__grid-item">
                                <h1 className="text-h1 AI-common-herostyle__text-description text-fw-medium gap-05x text-clr-white">
                                    <CountIncrement start={0} end={87} duration={5000} />
                                </h1>
                                <p className="text-p3 AI-common-herostyle__text-description text-fw-regular text-clr-secondary">
                                    Quick turnaround
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section
                className="AI-common-herostyle leverage-AI"
                style={{
                    background: `url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                }}
            >
                <div className="container container--banner">
                    <div className="AI-common-herostyle__text">
                        <div className="AI-common-herostyle__text-breadcrumbs text-fw-normal gap-2x text-clr-primary text-clr-white">
                            <Link className="text-decor-none" to="">
                                AI Chapter
                            </Link>
                            /
                            <Link className="text-decor-none" to="/ai-chapter/solutions">
                                Solutions
                            </Link>
                            /
                            <Link className="text-decor-none" to="">
                                Chitra
                            </Link>
                        </div>
                        <h1 className="text-h1 AI-common-herostyle__text-header text-fw-medium gap-2x text-clr-white">
                            Chitra
                        </h1>
                        <p className="text-p1 AI-common-herostyle__text-description text-fw-regular gap-2x text-clr-white">
                            Capture the Essence of Your Music <br />
                            with AI-Generated album artwork
                        </p>
                        <div>
                            <Link className="sdn-cta-btn" to="/contact/">
                                Contact us
                            </Link>
                        </div>
                    </div>
                    <div className="ai-demos-stats chitra-stats">
                        <p className="text-p1 AI-common-herostyle__text-description text-fw-regular gap-2x text-clr-white">
                            Musicians focus on music. Chitra perfects their album covers
                        </p>
                        <div className="ai-demos-stats__grid">
                            <div className="ai-demos-stats__grid-item">
                                <h1 className="text-h1 AI-common-herostyle__text-description text-fw-medium gap-05x text-clr-white">
                                    <CountIncrement start={0} end={100} duration={4000} showPlus />
                                </h1>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Layouts, fonts, colors and graphics
                                </p>
                            </div>
                            <div className="ai-demos-stats__grid-item">
                                <h1 className="text-h1 AI-common-herostyle__text-description text-fw-medium gap-05x text-clr-white">
                                    <CountIncrement start={0} end={100} duration={4000} />
                                </h1>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Download in high resolution
                                </p>
                            </div>
                            <div className="ai-demos-stats__grid-item">
                                <h1 className="text-h1 AI-common-herostyle__text-description text-fw-medium gap-05x text-clr-white">
                                    <CountIncrement start={0} end={87} duration={5000} />
                                </h1>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Quick turnaround
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="leverage-genAI-content demos-content">
                <div className="container container--first">
                    <div className="leverage-genAI-content__text">
                        <div className="text-h1 text-fw-medium gap-3x">
                            Transform Song Lyrics into Visually Captivating Album Covers with
                            AI-Driven Solution
                        </div>
                        <p className="text-p2 text-fw-regular text-clr-secondary gap-1x">
                            We're dedicated to transforming your music experience through creative
                            and innovative solutions. Introducing our cutting-edge AI solution -
                            Creative Harmonic Imagery Transformation Rendering Assistant (CHITRA).
                        </p>
                        <p className="text-p2 text-fw-regular text-clr-secondary gap-2x">
                            Chitra revolutionizes album artwork creation by harnessing the power of
                            AI- analyzing song lyrics, extracting keywords, and leveraging advanced
                            algorithms. Using it, artists and musicians can effortlessly generate
                            unique cover artwork that makes them stand out from the crowd with album
                            covers that are as unique as their music.
                        </p>
                    </div>
                </div>
            </section>
            {/* vidura explained  */}
            <section className="demo-explain-sec">
                <div className="container container--mid-v2">
                    <div className="leverage-genAI-content__text">
                        <div className="text-h1 text-fw-medium gap-4x explain-word">
                            Chitra Expl <span className="AI-word">ai</span>ned
                        </div>
                    </div>
                    <div className="">
                        <ExplainedDemoAI ListOfExplainAI={ListOfExplainAI} />
                    </div>
                </div>
            </section>
            <section className="Demos-video-sec">
                <div className="container container--mid-v2">
                    <div className="leverage-genAI-content__text">
                        <div className="text-h1 text-fw-medium gap-4x">
                            Get a Glimpse of the Future of Music Visuals
                        </div>
                        <div className="Demos-video-sec__demovideo">
                            <div className="ai-solutions-wrapper-list-item__restitems__inner-video">
                                <video
                                    loop
                                    autoPlay
                                    muted
                                    playsInline
                                    controlsList="nodownload"
                                    className="ai-video gap-2x"
                                >
                                    <source src={AppchitraVideo} type="video/mp4" />
                                </video>
                            </div>
                            <div>
                                <button
                                    onClick={() => {
                                        openForm(DEMOS.CHITRA);
                                    }}
                                    target="no_refer"
                                    className="sdn-cta-btn"
                                >
                                    Try Chitra Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* features of vidura  */}
            <section className="features-of-demos">
                <div className="container container--mid-v2">
                    <div className="features-of-demos__wrapper">
                        <div className="features-of-demos__grid">
                            <div className="features-of-demos__grid-item-left">
                                <div className="sticky-container">
                                    <h2 className="text-h1 text-fw-medium text-clr-primary gap-2x">
                                        Features of Chitra
                                    </h2>
                                    <div className="feature-img">
                                        <img
                                            // src="../../../../images/aichapter/Feature-of-vidura.svg"
                                            src={FeatureImgChitra}
                                            alt="feature-img"
                                            loading="lazy"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="features-of-demos__grid-item-right-containerbox">
                                <FeaturesOfDemos featuresList={ListOfChitraFeatures} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="why-demos-sec">
                <div className="container container--mid-v2">
                    <div className="text-h1 text-fw-medium gap-4x">Why Chitra?</div>
                    <div className="why-demos-sec__grid">
                        <div className="why-demos-sec__grid-item">
                            <div className="animation">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: ChitraCreativity,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                            <div className="content">
                                <h3 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                                    Creativity
                                </h3>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Let Chitra unleash your creativity with its unique and visually
                                    captivating album artwork designs.
                                </p>
                            </div>
                        </div>
                        <div className="why-demos-sec__grid-item">
                            <div className="animation">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: ChitraEfficiency,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                            <div className="content">
                                <h3 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                                    Efficiency
                                </h3>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Save time and effort with Chitra's automated design process,
                                    allowing you to focus on what you do best - making music.
                                </p>
                            </div>
                        </div>
                        <div className="why-demos-sec__grid-item">
                            <div className="animation">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: ChitraPersonalization,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                            <div className="content">
                                <h3 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                                    Personalization
                                </h3>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Customize album covers that reflect your unique style, ensuring
                                    a memorable visual for your audience.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="navigate-cta">
                <div className="container container--last-v2">
                    <NavigateBlockV2
                        button="Contact us"
                        title="Ready to elevate your music with visually stunning album artwork?"
                    />
                </div>
            </section>
            <GenericModal
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <CollectEmailAddressForm demoFor={openFor} redirectTo={redirectUrl} />
            </GenericModal>
        </Layout>
    );
};

export default Imchitra;
